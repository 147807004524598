import {
  CATEGORY_MENU_MOBILE_PARAMS,
  CATEGORY_MENU_PARAMS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS } from '@wix/communities-blog-experiments';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

const { isMobileDisplaySettingsEnabled, isMobileDesignSettingsEnabled, isMobileLayoutSettingsEnabled } =
  CATEGORY_MENU_MOBILE_PARAMS;

const getCategoryMenuDisplayParams = (state) =>
  getUseMobileSettings(state, isMobileDisplaySettingsEnabled.appSettingsPath)
    ? CATEGORY_MENU_MOBILE_PARAMS
    : CATEGORY_MENU_PARAMS;

const getUseMobileSettings = (state, wixParamPath) =>
  isExperimentEnabled(state, EXPERIMENT_CATEGORY_MENU_MOBILE_SETTINGS) &&
  getIsMobile(state) &&
  getIsMobileSettingEnabled(state, wixParamPath);

export const getUseMobileDesignSettings = (state) =>
  getUseMobileSettings(state, isMobileDesignSettingsEnabled.appSettingsPath);

export const getUseMobileLayoutSettings = (state) =>
  getUseMobileSettings(state, isMobileLayoutSettingsEnabled.appSettingsPath);

export const getShowAllPostsCategory = (state) => {
  const { showAllPostsCategory } = getCategoryMenuDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showAllPostsCategory.appSettingsPath,
    fallback: showAllPostsCategory.defaultValue,
  });
};

export const getShowPostCount = (state) => {
  const { showPostCount } = getCategoryMenuDisplayParams(state);

  return getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });
};
