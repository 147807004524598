import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getFeedTitle } from '@wix/communities-blog-client-common';
import { getViewerAllPostsLabelTranslation } from '../../common/selectors/viewer-all-post-label-translation-selectors';
import { getShowAllPostsCategory } from '../selectors/category-menu-selectors';

const getCategoryMenuWidgetData = (state) => state.categoryMenuWidget;

export const getCategoryMenu = createSelector(
  getCategoryMenuWidgetData,
  getShowAllPostsCategory,
  getFeedTitle,
  getViewerAllPostsLabelTranslation,
  (categoryMenu, showAllPosts, title, translatedTitle) => {
    const parsedCategoryMenu = { ...categoryMenu, categoryMenu: [...categoryMenu.categoryMenu] };
    if (showAllPosts && get(categoryMenu, 'categoryMenu[0].display.text')) {
      parsedCategoryMenu.categoryMenu[0].display.text = translatedTitle || title;
    } else {
      parsedCategoryMenu.categoryMenu = categoryMenu.categoryMenu.slice(1);
    }
    return parsedCategoryMenu;
  },
);
