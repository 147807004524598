import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import { EXPERIMENT_USE_CATEGORY_WIDGET_ADAPTER } from '@wix/communities-blog-experiments';
import { initI18n } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import CategoryMenu from '../category-menu';
import CategoryMenuNew from '../category-menu/category-menu-new';
import styles from './app-root.scss';
import withExperiment from '../../../common/hoc/with-experiment';

class AppRoot extends React.Component {
  getI18nConfig = () => {
    const { language, translations } = this.props;

    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { isUseCategoryWidgetAdapterEnabled } = this.props;

    if (isUseCategoryWidgetAdapterEnabled) {
      return (
        <ResponsiveListener>
          <div className={styles.baseStyles}>
            <CategoryMenuNew />
            <AppLoaded />
          </div>
        </ResponsiveListener>
      );
    }

    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <I18nextProvider i18n={i18n}>
          <div className={styles.baseStyles}>
            <CategoryMenu />
            <AppLoaded />
          </div>
        </I18nextProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.object.isRequired,
  sectionUrl: PropTypes.string.isRequired,
  isUseCategoryWidgetAdapterEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  sectionUrl: getSectionUrl(state),
});

export default flowRight(
  withReduxStore,
  withExperiment({ isUseCategoryWidgetAdapterEnabled: EXPERIMENT_USE_CATEGORY_WIDGET_ADAPTER }),
  connect(mapRuntimeToProps),
)(AppRoot);
